document.addEventListener("DOMContentLoaded", function () {
  const itemsPerPage = 10;
  let currentIndex = 0;
  let allPosts = Array.from(document.querySelectorAll(".p-work__card"));
  let filteredPosts = allPosts;

  const savedFilter = localStorage.getItem('filter');
  const savedIndex = localStorage.getItem('currentIndex');
  if (savedFilter) {
    currentIndex = savedIndex ? parseInt(savedIndex, 10) : 0;
    restoreFilter(savedFilter);
  } else {
    showPosts(allPosts);
  }
  toggleLoadMoreButton();

  function showPosts(posts) {
    allPosts.forEach((post) => {
      post.classList.add("is-hide");
      post.classList.remove("is-view");
    });

    posts.forEach((post, index) => {
      if (index < currentIndex + itemsPerPage) {
        post.classList.add("is-view");
        post.classList.remove("is-hide");
      }
    });
  }

  function toggleLoadMoreButton() {
    const loadMoreButton = document.getElementById("load-more");
    if (filteredPosts.length > currentIndex + itemsPerPage) {
      loadMoreButton.style.display = "block";
    } else {
      loadMoreButton.style.display = "none";
    }
  }

  function restoreFilter(filter) {
    document.querySelectorAll(".p-work__form__button").forEach((button) => {
      button.classList.remove("is-current");
    });

    const activeButton = document.querySelector(`[data-filter="${filter}"]`);
    if (activeButton) {
      activeButton.classList.add("is-current");
    }

    if (filter === "all") {
      filteredPosts = allPosts;
    } else {
      filteredPosts = allPosts.filter((post) => {
        const categories = post.getAttribute("data-category").split(" ");
        return categories.includes(filter.slice(1));
      });
    }

    showPosts(filteredPosts);
  }

  document.querySelectorAll(".p-work__form__button").forEach((button) => {
    button.addEventListener("click", function () {
      const filter = this.dataset.filter;
      currentIndex = 0;

      localStorage.setItem('filter', filter);
      localStorage.setItem('currentIndex', currentIndex);

      document.querySelectorAll(".p-work__form__button").forEach((btn) => {
        btn.classList.remove("is-current");
      });
      this.classList.add("is-current");

      if (filter === "all") {
        filteredPosts = allPosts;
      } else {
        filteredPosts = allPosts.filter((post) => {
          const categories = post.getAttribute("data-category").split(" ");
          return categories.includes(filter.slice(1));
        });
      }

      showPosts(filteredPosts);
      toggleLoadMoreButton();
    });
  });

  document.getElementById("load-more").addEventListener("click", function () {
    currentIndex += itemsPerPage;
    
    localStorage.setItem('currentIndex', currentIndex);

    showPosts(filteredPosts);
    toggleLoadMoreButton();
  });
});
